<template>
  <v-dialog v-model="modalData.dialog" max-width="650px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Source Type
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3" md="3">
                <v-select v-model="selectedLocale" :items="languages">
                  <template slot="selection" slot-scope="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="selectedLocale.flag" alt="" />
                    </span>
                    {{ slotProps.item.name }}
                  </template>
                  <template v-slot:item="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="slotProps.item.flag" alt="" />
                    </span>
                    <span class="navi-text">{{ slotProps.item.name }}</span>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  :rules="nameRules"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :key="selectedLocale.lang + '-name'"
                  label="Source Type name"
                  :id="dynamicID"
                  :error-messages="
                    messages['translations.' + selectedLocale.lang + '.name']
                  "
                  @keyup="
                    messages['translations.' + selectedLocale.lang + '.name'] =
                      ''
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            v-if="
              (permissionCan('create') && isNotEditedItem) ||
              permissionCan('update')
            "
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { ENDPOINT } from "./SourceTypes";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = () => ({
  id: null,
  status: 1,
  translations: {},
});

export default {
  name: "SourceTypeModalForm",
  props: ["modalData", "editedItem", "permissions", "statuses"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: initialFormData(),
      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) => v.length < 255 || "This field must be max 255 characters",
      ],
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),j
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    isNotEditedItem() {
      if (this.editedItem.id) {
        return 0;
      } else {
        return 1;
      }
    },
    formTitle() {
      return this.editedItem.id ? "Edit Source Type" : "New Source Type";
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    editedItem(value) {
      this.selectedLocale = i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      });
      this.formModel = value;
      this.setTranslatedAttributes();
      this.resetErrorMessages();
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    // handleCloseModalForm() {
    //   this.resetErrorMessages();
    //   this.$emit("closeModalForm");
    // },

    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();
      if (!this.formValid) return;

      if (this.formValid) {
        this.resetErrorMessages();
        this.modalData.loading = true;

        this.loader = true;
        if (model.id) {
          // alert("PUT " + model.id);
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.modalData.loading = false;
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, this.formModel));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          this.loader = true;
          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              this.snackbar = true;
              this.modalData.loading = false;
              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleNew() {
      this.formModel = this.initialFormData();
      this.resetErrorMessages();
      this.setTranslatedAttributes(true);
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "sales.sourceType." + action
      );
    },
  },

  async mounted() {
    // this.fetchCurrency();
  },
};
</script>
